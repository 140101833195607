const getEnvironmentKey = () => {
  if (typeof window !== 'undefined') return window.environment;
  if (typeof process !== 'undefined') return process.env.NODE_ENV;
  return 'development';
};

const environmentKey = getEnvironmentKey();

const environment = {
  development: {
    isProduction: false,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_developmentexogenrx_1/0oanqvhlm4Q5U6iDb0h7/alnnqvk19dogsAQIF0h7',
    apiPort: '8000',
    apiTimeout: {
      response: 120000,
      deadline: 120000
    },
    features: {
      parallelStatus: true
    }
  },
  staging: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_stagingexogenrx_1/0oanqvil4x4oNgoVs0h7/alnnqvmi03gNjwB3J0h7',
    apiPort: '8000',
    features: {
      parallelStatus: true
    }
  },
  test: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.oktapreview.com',
    oktaRedirect:
      'https://bioventus.oktapreview.com/home/bioventus_testexogenrx_1/0oanqvil4x4oNgoVs0h7/alnnqvmi03gNjwB3J0h7',
    apiPort: '8000',
    features: {
      parallelStatus: true
    }
  },
  training: {
    isProduction: true,
    docusignDetailUrl: 'https://appdemo.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_exogenrxtraining_1/0oacesu4eqdka4bZJ1t7/alncesw4vzeHJcVWc1t7',
    apiPort: '8000',
    features: {
      parallelStatus: true
    }
  },
  production: {
    isProduction: true,
    docusignDetailUrl: 'https://app.docusign.com/documents/details/',
    oktaBaseUrl: 'https://bioventus.okta.com',
    oktaRedirect:
      'https://bioventus.okta.com/home/bioventus_exogenrx_1/0oacesuh59gRAoarO1t7/alncesx1ax53khulb1t7',
    apiPort: '80',
    features: {
      parallelStatus: false
    }
  }
}[environmentKey || 'development'];

module.exports = Object.assign(
  {
    app: {
      title: 'MyBV360',

      description: '',
      head: {
        titleTemplate: '%s',
        meta: [{ charset: 'utf-8' }]
      }
    },
    apiSegment: '',
    apiTimeout: {
      response: 120000,
      deadline: 120000
    },
    recaptchaSiteKeyV3: '6LfSLroUAAAAAMJ39fOVJKRihPnm8_llfjiObxCx'
  },
  environment
);
